import { Route } from '@angular/router';
import { DefaultLayout } from './layouts/default/default.layout';
import { authGuard } from './libs/auth.guard';
import { TabsLayout } from './layouts/tabs/tabs.layout';

export const appRoutes: Route[] = [
  {
    path: '',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: TabsLayout,
        children: [
          {
            path: 'home',
            loadComponent: () => import('./pages/home/home.page'),
          },
          {
            path: 'attraction',
            loadComponent: () => import('./pages/attraction/attraction.page'),
          },
          {
            path: 'product',
            loadComponent: () => import('./pages/product/product.page'),
          },
          {
            path: 'co-buying',
            loadComponent: () => import('./pages/co-buying/co-buying.page'),
          },
          {
            path: 'my-page',
            canActivateChild: [authGuard],
            children: [
              {
                path: '',
                loadComponent: () => import('./pages/my-page/my-page.page'),
              },
            ],
          },
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'callback',
        children: [
          {
            path: 'kakao',
            loadComponent: () => import('./pages/callback/kakao/kakao-callback.page'),
          },
          {
            path: 'apple',
            loadComponent: () => import('./pages/callback/apple/apple-callback.page'),
          },
        ],
      },
    ],
  },
  { path: 'term', loadComponent: () => import('./pages/term/term.page') },
];
