import { Component, inject } from '@angular/core';
import {
  IonIcon,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonApp,
  IonRouterOutlet,
  IonProgressBar,
} from '@ionic/angular/standalone';
import { AuthStore } from '../../stores/auth.store';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-default',
  standalone: true,
  imports: [
    IonProgressBar,
    IonRouterOutlet,
    IonApp,
    IonHeader,
    IonTabButton,
    IonTabs,
    IonTabBar,
    IonIcon,
    IonToolbar,
    IonTitle,
    RouterLink,
  ],
  templateUrl: './default.layout.html',
  styleUrl: './default.layout.scss',
})
export class DefaultLayout {
  authStore = inject(AuthStore);
}
