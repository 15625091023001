import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonContent, IonModal, IonHeader } from '@ionic/angular/standalone';
import { derivedAsync } from 'ngxtension/derived-async';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { SettingStore } from '../../stores/setting.store';
import { ButtonComponent, SafeHtmlPipe } from '@common/angular';
import { BaseModal } from '../base.modal';

@Component({
  selector: 'app-term',
  standalone: true,
  imports: [IonHeader, IonContent, CommonModule, ModalHeaderComponent, ButtonComponent, SafeHtmlPipe],
  templateUrl: './term.modal.html',
  styleUrl: './term.modal.scss',
})
export class TermModal extends BaseModal {
  modal!: IonModal;
  term!: string;
  store = inject(SettingStore);
  sanitizer = inject(DomSanitizer);

  content = derivedAsync(() => {
    const rawContent = this.store.get(`term:${this.term}` as any);
    if (!rawContent) return '';
    return rawContent;
  });
}
