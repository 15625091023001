<ion-tabs>
  <ion-tab-bar slot="bottom" class="border-t border-neutral-200">
    <ion-tab-button tab="home">
      <ion-icon src="icons/home.svg" />
      홈
    </ion-tab-button>
    <ion-tab-button tab="attraction">
      <ion-icon src="icons/attraction.svg" />
      관광지
    </ion-tab-button>
    <ion-tab-button tab="product">
      <ion-icon src="icons/shop.svg" />
      상품
    </ion-tab-button>
    <ion-tab-button tab="co-buying">
      <ion-icon src="icons/co-buying.svg" />
      공동구매
    </ion-tab-button>
    <ion-tab-button tab="my-page">
      <ion-icon src="icons/user.svg" />
      마이페이지
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
