import { inject } from '@angular/core';
import { CanActivateChildFn } from '@angular/router';
import { AuthStore } from '../stores/auth.store';
import { ModalController } from '@ionic/angular/standalone';
import { LoginModal } from '../modals/login/login.modal';

export const authGuard: CanActivateChildFn = async () => {
  const store = inject(AuthStore);
  const modalController = inject(ModalController);
  if (!store.member()) await store.fetchMember();
  if (!store.member()) await modalController.create({ component: LoginModal }).then((modal) => modal.present());

  return !!store.member();
};
