<app-modal-header />
<ion-content class="ion-padding">
  <main class="flex flex-col h-full gap-4">
    @if (!emailFound()) {
    <section class="flex flex-col items-center gap-4">
      <h1>이메일 찾기</h1>
      <p>이메일을 찾으시려면 아래 항목을 입력해 주세요.</p>
    </section>

    <form [formGroup]="form" class="flex flex-col flex-1" (ngSubmit)="submit()">
      <div class="flex flex-col flex-1 gap-4">
        <app-input-text formControlName="name" label="이름" placeholder="이름을 입력해 주세요" />
        <app-input-tel formControlName="tel" label="전화번호" placeholder="전화번호를 입력해 주세요" />
      </div>

      <app-button type="submit" [disabled]="form.invalid">확인</app-button>
    </form>
    } @else {
    <section class="flex flex-col items-center gap-4">
      <h1>이메일 찾기</h1>
      <p>입력하신 정보로 가입하신 이메일 주소 입니다.</p>
    </section>

    <section class="flex flex-col flex-1">
      <div class="flex flex-col flex-1">
        <div class="flex flex-col items-center justify-center py-6 bg-neutral-100">
          <p>{{emailFound()}}</p>
        </div>
      </div>
      <app-button (click)="modal.dismiss()">확인</app-button>
    </section>
    }
  </main>
</ion-content>
