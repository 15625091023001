import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonTabs, IonTabBar, IonTabButton, IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [IonIcon, IonTabButton, IonTabBar, IonTabs, CommonModule],
  templateUrl: './tabs.layout.html',
  styleUrl: './tabs.layout.scss',
})
export class TabsLayout {}
