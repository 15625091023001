<app-modal-header />
<ion-header> </ion-header>
<ion-content class="ion-padding">
  @switch (term) { @case('eula') {
  <h1>서비스이용약관</h1>
  } @case ('privacy') {
  <h1>개인정보처리방침</h1>
  } }
  <article [innerHTML]="content() | safeHtml" class="mt-8"></article>
</ion-content>
<section class="p-4">
  <app-button (click)="modal.dismiss()">확인</app-button>
</section>
