import { Component, inject, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VersionService } from '@api-client';
import { App } from '@capacitor/app';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { ToastService } from '@common/angular';
import { IonProgressBar, IonRouterOutlet, Platform, IonApp, ModalController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { LocationStore } from './stores/location.store';
import { Toast } from '@capacitor/toast';
import { SafeArea } from 'capacitor-plugin-safe-area';

addIcons(icons);

@Component({
  standalone: true,
  imports: [IonApp, IonProgressBar, RouterModule, IonRouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  service = inject(VersionService);
  platform = inject(Platform);
  toast = inject(ToastService);
  locationStore = inject(LocationStore);
  modalController = inject(ModalController);
  routerOutlet = inject(IonRouterOutlet, { optional: true });
  isUpdating = signal<boolean>(false);
  progress = signal<number>(0);

  constructor() {
    if (this.platform.is('capacitor')) {
      App.addListener('appStateChange', async (state) => {
        if (state.isActive) {
          this.checkVersion();
        }
      });

      this.platform.backButton.subscribeWithPriority(10, () => {
        if (!this.routerOutlet?.canGoBack()) {
          Toast.show({
            text: '한번 더 누르시면 앱이 종료됩니다.',
          });
          this.platform.backButton.subscribeWithPriority(10, () => {
            App.exitApp();
          });
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.platform.is('capacitor')) {
      this.checkVersion();
    }

    window.addEventListener('popstate', async (ev) => {
      const openModal = await this.modalController.getTop();
      if (openModal) {
        openModal.dismiss();
        ev.preventDefault();
        ev.stopPropagation();
      }
    });

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
      }
    });
  }

  checkVersion() {
    this.service.versionControllerGetLatest().subscribe(async ({ version, url }) => {
      const currentVersion = await CapacitorUpdater.current().then((v) => v.bundle.version);
      if (version === currentVersion) {
        await CapacitorUpdater.notifyAppReady();
        return;
      } else {
        this.update(version, url);
      }
    });
  }

  async update(version: string, url: string) {
    this.isUpdating.set(true);
    CapacitorUpdater.addListener('download', (ev) => this.progress.set(ev.percent / 100));

    const updatedBundle = await CapacitorUpdater.download({ version, url });

    await CapacitorUpdater.set(updatedBundle);
    await CapacitorUpdater.notifyAppReady();
    await CapacitorUpdater.removeAllListeners();
  }
}
