import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ButtonComponent,
  InputEmailComponent,
  InputTextComponent,
  InputPasswordComponent,
  InputTelComponent,
  ToastService,
} from '@common/angular';
import { MemberService } from '@api-client';
import { IconComponent } from '@common/angular';
import { BaseModal } from '../base.modal';

@Component({
  selector: 'app-find-password',
  standalone: true,
  imports: [
    IonContent,
    CommonModule,
    ModalHeaderComponent,
    FormsModule,
    InputEmailComponent,
    InputTextComponent,
    InputTelComponent,
    ReactiveFormsModule,
    ButtonComponent,
    InputPasswordComponent,
    IconComponent,
  ],
  templateUrl: './find-password.modal.html',
  styleUrl: './find-password.modal.scss',
})
export class FindPasswordModal extends BaseModal {
  service = inject(MemberService);
  toast = inject(ToastService);
  modal!: IonModal;

  verified = signal<boolean>(false);
  token = signal<string | undefined>(undefined);
  updated = signal<boolean>(false);

  findPasswordForm = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    tel: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  resetPasswordForm = new FormGroup({
    password: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    passwordConfirm: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  submit() {
    if (this.findPasswordForm.invalid) return;
    this.service
      .memberControllerFindPassword({
        body: this.findPasswordForm.getRawValue(),
      })
      .subscribe(({ token }) => {
        this.verified.set(true);
        this.token.set(token);
      });
  }

  setPassword() {
    if (this.resetPasswordForm.invalid) return;
    this.service
      .memberControllerSetPassword({
        body: { ...this.resetPasswordForm.getRawValue(), token: this.token()! },
      })
      .subscribe(() => {
        this.updated.set(true);
      });
  }
}
