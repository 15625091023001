///<reference types="kakao-js-sdk" />
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { OAuthService } from '@api-client';
import { ButtonComponent, IconComponent, InputEmailComponent, InputPasswordComponent, ToastService } from '@common/angular';
import { IonContent, IonModal, ModalController, Platform } from '@ionic/angular/standalone';
import { KakaoLoginPlugin } from 'capacitor-kakao-login-plugin';
import { environment } from '../../../environments/environment';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { AuthStore } from '../../stores/auth.store';
import { FindEmailModal } from '../find-email/find-email.modal';
import { FindPasswordModal } from '../find-password/find-password.modal';
import { SignupModal } from '../signup/signup.modal';
import { TermModal } from '../term/term.modal';
import { Preferences } from '@capacitor/preferences';
import { AdditionalInfoModal } from '../additional-info/additional-info.modal';
import { BaseModal } from '../base.modal';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { MEMBER_ACCESS_TOKEN_KEY, MEMBER_REFRESH_TOKEN_KEY } from '@common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonContent,
    ModalHeaderComponent,
    InputEmailComponent,
    InputPasswordComponent,
    ButtonComponent,
    IconComponent,
  ],
  templateUrl: './login.modal.html',
  styleUrl: './login.modal.scss',
})
export class LoginModal extends BaseModal {
  form = new FormGroup({
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    password: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  modal!: IonModal;

  authStore = inject(AuthStore);
  toast = inject(ToastService);
  oauthService = inject(OAuthService);
  platform = inject(Platform);
  modalController = inject(ModalController);

  get isIOS() {
    return this.platform.is('mobile') && this.platform.is('ios');
  }

  async submit() {
    if (this.form.valid) {
      await this.authStore.login(this.form.getRawValue());
      this.toast.success('로그인 되었습니다.');
      this.modalController.dismiss();
    }
  }

  openSignupModal() {
    this.modalController.create({ component: SignupModal }).then((m) => m.present());
  }

  openFindEmailModal() {
    this.modalController.create({ component: FindEmailModal }).then((m) => m.present());
  }

  openFindPasswordModal() {
    this.modalController.create({ component: FindPasswordModal }).then((m) => m.present());
  }

  openEULAModal() {
    this.modalController.create({ component: TermModal, componentProps: { term: 'eula' } }).then((m) => m.present());
  }

  openPrivacyPolicyModal() {
    this.modalController.create({ component: TermModal, componentProps: { term: 'privacy' } }).then((m) => m.present());
  }

  async openKakaoLogin() {
    if (this.platform.is('capacitor')) {
      const { accessToken } = await KakaoLoginPlugin.goLogin();
      this.oauthService
        .oauthControllerKakaoLogin({
          token: accessToken,
        })
        .subscribe({
          next: async ({ accessToken, refreshToken }) => {
            await Preferences.set({ key: MEMBER_ACCESS_TOKEN_KEY, value: accessToken });
            await Preferences.set({ key: MEMBER_REFRESH_TOKEN_KEY, value: refreshToken });
            await this.authStore.fetchMember();
            this.toast.success('로그인 되었습니다.');
            this.modal.dismiss();

            if (this.authStore.additionalInfoRequired()) {
              this.modalController
                .create({
                  component: AdditionalInfoModal,
                  componentProps: {
                    name: this.authStore.member()?.name,
                    email: this.authStore.member()?.email,
                    tel: this.authStore.member()?.tel,
                  },
                })
                .then((modal) => modal.present());
            }
          },
        });
    } else {
      const isInitialized = Kakao.isInitialized();
      if (!isInitialized) Kakao.init(environment.kakaoAppKey);

      Kakao.Auth.authorize({
        redirectUri: `${window.origin}/callback/kakao`,
      });
    }
  }

  async appleLogin() {
    const options: SignInWithAppleOptions = {
      clientId: 'com.samcheonpotoday.web',
      redirectURI: `${window.origin}/callback/apple`,
      scopes: 'email',
      state: 'state',
      nonce: 'nonce',
    };

    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        this.oauthService
          .oauthControllerAppleLogin({
            authorization_code: result.response.authorizationCode,
          })
          .subscribe({
            next: async (tokens) => {
              await Preferences.set({ key: MEMBER_ACCESS_TOKEN_KEY, value: tokens.accessToken });
              await Preferences.set({ key: MEMBER_REFRESH_TOKEN_KEY, value: tokens.refreshToken });
              await this.authStore.fetchMember();
              this.toast.success('로그인 되었습니다.');
              this.modal.dismiss();
            },
          });
      })
      .catch((error) => {
        // Handle error
      });
  }
}
